import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// Toast notification - https://github.com/ankurk91/vue-toast-notification/tree/v1.x
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, { position: 'top-right' });0
// Vue click outside - https://github.com/vue-bulma/click-outside
import ClickOutside from 'vue-click-outside';
Vue.directive('click-outside', ClickOutside);
// Highlight syntax - https://github.com/metachris/vue-highlightjs
import VueHighlightJS from 'vue-highlightjs';
Vue.use(VueHighlightJS);
// Autosize
import autosize from 'autosize';
Vue.prototype.$autosize = autosize;
// Directives 
import tooltipDirective from './directives/tooltip.directive';
Vue.directive('tooltip', tooltipDirective);
// Filters
import dateFilter from '@/filters/date.filter';
Vue.filter('date', dateFilter);
// Event bus
Vue.prototype.$eventBus = new Vue();
// Deep copy
Vue.prototype.$deepCopy = function (obj) {
	return JSON.parse(JSON.stringify(obj));
}

Vue.config.productionTip = false;

async function getConfig() {
  let config;
  if (process.env.NODE_ENV === 'production') {
    config = await fetch(process.env.BASE_URL + 'config/prod.env.json');
  } else if (process.env.NODE_ENV === 'development') {
    config = await fetch(process.env.BASE_URL + 'config/dev.env.json');
  }
  return await config.json();
};

getConfig().then(config => {
	Vue.prototype.$config = config;
}).then(() => {
	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app');
})


