import axios from 'axios';
import templates from '../../data/templates';

export default {
	state: {
		templates: []
	},
	getters: {
		templates: s => s.templates
	},
	mutations: {
		setTemplates(state, templates) {
			state.templates = templates;
		}
	},
	actions: {
		async loadDefaultTemplates({ getters }) {
			return new Promise(resolve => {
				const promises = [];

				for (let i = 0; i < templates.length; i++) {
					const promise = axios.post(`${getters.strapiUrl}/api/templates/`, { 
						data: templates[i] 
					}, getters.requestHeader);

					promises.push(promise);
				}

				Promise.all(promises).then((templates) => {
					resolve(templates.map(template => template.data.data));
				});
			});
		},

		async getTemplates({ commit, getters, dispatch }) {
			const response = await axios.get(`${getters.strapiUrl}/api/templates`, getters.requestHeader);
			let templates = response.data.data;
			if (templates.length === 0) {
				templates = await dispatch('loadDefaultTemplates');
				commit('setTemplates', templates);
			} else { 
				commit('setTemplates', templates);
			}
			return templates;
		},
	}
}