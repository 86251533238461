import axios from "axios";

export default {
	actions: {
		async getMultipartFormData(_, itemData) {
      const formData = new FormData();
      const data = {};

      for (const [key, value] of Object.entries(itemData)) {
				if (key === 'image') {
					if (value instanceof File) {
						formData.append('files.media', value, value.name);
					}
        } else {
          data[key] = value;
        }
      }
			
      formData.append("data", JSON.stringify(data));
      return formData;
    },

		async createImageFile(_, { url, name, mime }) {
			const response = await fetch(url);
			const blob = await response.blob();
			return new File([blob], name, { type: mime });
		},

		async deleteImage({getters}, imageId) {
			if (getters.superadminPreviewUser) throw new Error('You cannot delete an image while previewing as a superadmin.');
			return await axios.delete(`${getters.strapiUrl}/api/upload/files/${imageId}`, getters.requestHeader);
		},
	}
}