import axios from 'axios';
import combo from '../../data/combo';
import feedback from '../../data/feedback';

export default {
	state: {
		comboIcons: [],
		feedbackIcons: [],
	},
	getters: {
		comboIcons: state => state.comboIcons,
		feedbackIcons: state => state.feedbackIcons,
	},
	mutations: {
		setComboIcons(state, comboIcons) {
			state.comboIcons = comboIcons;
		},
		setFeedbackIcons(state, feedbackIcons) {
			state.feedbackIcons = feedbackIcons;
		}
	},
	actions: {
		async getComboIcons({ commit, getters, dispatch }) {
			const response = await axios.get(`${getters.strapiUrl}/api/assets?filters[name][$containsi]=comboIcon&populate=image`);
			let comboIcons = response.data.data;
			if (comboIcons.length === 0) {
				comboIcons = await dispatch('loadComboIcons');
				commit('setComboIcons', comboIcons);
			} else { 
				commit('setComboIcons', comboIcons);
			}
			return comboIcons;
		},

		async getFeedbackIcons({ commit, getters, dispatch }) {
			const response = await axios.get(`${getters.strapiUrl}/api/assets?filters[name][$containsi]=feedbackIcon&populate=image`);
			let feedbackIcons = response.data.data;
			if (feedbackIcons.length === 0) {
				feedbackIcons = await dispatch('loadFeedbackIcons');
				commit('setFeedbackIcons', feedbackIcons);
			} else {
				commit('setFeedbackIcons', feedbackIcons);
			}
			return feedbackIcons;
		},

		async loadComboIcons({ getters, dispatch }) {
			const comboIcons = [];
			for (let i = 0; i < combo.length; i++) {
				const imageName = combo[i].data.imageName;
				const relativeUrl = require(`../../assets/images/combo/${imageName}`);
				const imagePath = process.env.NODE_ENV === 'development' ?
					`http://localhost:8080${relativeUrl}` :
					`https://app.releasecat.io${relativeUrl}`;
				
				const imageFile = await dispatch('createImageFile', { url: imagePath, name: imageName, mime: 'image/png' });
				const assetData = {
					image: imageFile,
					data: {
						name: combo[i].name,
						data: combo[i].data
					}
				}

				const multipartedFormData = await dispatch('getMultipartFormData', assetData);
				const icon = await axios.post(`${getters.strapiUrl}/api/assets`, 
					multipartedFormData, 
					{
						headers: {
							...getters.requestHeader.headers,
							'Content-Type': 'multipart/form-data'
						}
					}
				);
				comboIcons.push(icon.data.data);
			}
			return comboIcons;
		},

		async loadFeedbackIcons({ getters, dispatch }) {
			const feedbackIcons = [];
			for (let i = 0; i < feedback.length; i++) {
				const imageName = feedback[i].data.imageName;
				const relativeUrl = require(`../../assets/images/feedback/${imageName}`);
				const imagePath = process.env.NODE_ENV === 'development' ?
					`http://localhost:8080${relativeUrl}` :
					`https://app.releasecat.io${relativeUrl}`;

				const imageFile = await dispatch('createImageFile', { url: imagePath, name: imageName, mime: 'image/svg' });
				const assetData = {
					image: imageFile,
					data: {
						name: feedback[i].name,
						data: feedback[i].data
					}
				}

				const multipartedFormData = await dispatch('getMultipartFormData', assetData);
				const feedbackIcon = await axios.post(`${getters.strapiUrl}/api/assets`, 
					multipartedFormData, 
					{
						headers: {
							...getters.requestHeader.headers,
							'Content-Type': 'multipart/form-data'
						}
					}
				);
				feedbackIcons.push(feedbackIcon.data.data);
			}
			return feedbackIcons;
		}
	}
}
