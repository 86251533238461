import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.module';
import stream from './modules/stream.module';
import banner from './modules/banner.module';
import field from './modules/field.module';
import media from './modules/media.module';
import template from './modules/template.module';
import asset from './modules/asset.module';
import subscription from './modules/subscription.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
		strapiUrl: '',
		autoSaveQueue: [],
		superadminPreviewUser: null
  },
	getters: {
		strapiUrl: state => state.strapiUrl,
		currentDate: () => {
			const now = new Date();
			let month = (now.getMonth() + 1);               
			let day = now.getDate();
			if (month < 10) 
					month = "0" + month;
			if (day < 10) 
					day = "0" + day;
			const today = now.getFullYear() + '-' + month + '-' + day;
			return today;
		},
		superadminPreviewUser: state => state.superadminPreviewUser,
	},
  mutations: {
		setStrapiUrl(state, strapiUrl) {
			state.strapiUrl = strapiUrl
		},
		setSuperadminPreviewUser(state, user) {
			state.superadminPreviewUser = user;
		}
  },
  actions: {
	},
  modules: {
		auth,
		stream,
		banner,
		field,
		media,
		template,
		asset,
		subscription
  }
});
