import axios from 'axios';

export default {
	state: {
		streams: [],
		activeStream: null,
	},
	getters: {
		streams: state => state.streams,
		activeStream: state => state.activeStream,
		stream: state => (id) => state.streams.find(stream => stream.id === id)
	},
	mutations: {
		setStreams(state, streams) {
			state.streams = streams;
		},
		setActiveStream(state, stream) {
			state.activeStream = stream;
		},
		setActiveStreamName(state, name) {
			state.activeStream.attributes.name = name;
		}
	},
	actions: {
		async getStreams({ commit, getters }, authorId) {
			let response;
			if (authorId) {
				response = await axios.get(`${getters.strapiUrl}/api/streams?author=${authorId}`, getters.requestHeader);
			} else {
				response = await axios.get(`${getters.strapiUrl}/api/streams`, getters.requestHeader);
			}
			const streams = response.data.data;
			commit('setStreams', streams);
			return streams;
		},

		async createStream({ commit, getters }, streamData) {
			if (getters.superadminPreviewUser) throw new Error('You cannot create a stream while previewing as a superadmin.');
			const response = await axios.post(`${getters.strapiUrl}/api/streams`, { data: streamData }, getters.requestHeader);
			const stream = response.data.data;
			commit('setStreams', [...getters.streams, stream]);
			return stream;
		},

		async updateStream({ commit, getters }, { data, id }) {
			if (getters.superadminPreviewUser) throw new Error('You cannot update a stream while previewing as a superadmin.');
			const response = await axios.put(`${getters.strapiUrl}/api/streams/${id}`, { data }, getters.requestHeader);
			const stream = response.data.data;
			if (getters.activeStream && getters.activeStream.id === id) {
				commit('setActiveStream', stream);
			}
			commit('setStreams', getters.streams.map(s => s.id === stream.id ? stream : s));
			return stream;
		}
	}
}