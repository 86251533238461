<template>
  <div id="app">
   	<component :is="layout"></component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import DashboardLayout from '@/layouts/DashboardLayout';

export default {
	components: {
		EmptyLayout,
		DashboardLayout
	},
	computed: {
		layout() {
      return (this.$route.meta.layout || 'Empty') + 'Layout'
    }
	},
	methods: {
		onResize() {
			this.$eventBus.$emit('windowResize');
		}
	},
	created() {
		this.$store.commit('setStrapiUrl', this.$config.STRAPI_HOST);
		window.addEventListener('resize', this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}
}
</script>

<style lang="sass">
@import './assets/sass/_variables'

*
	margin: 0
	padding: 0
	box-sizing: border-box
	outline: none
	font-family: 'Lato', sans-serif
	scrollbar-width: thin
	scrollbar-color: $accent-color #f0f0f0
	// max-width: 100%

button
	border: none
	background: transparent

.v-toast__item--success
	background: #70c276 !important

.v-toast__item--error
	background: #c44d56 !important

::-webkit-scrollbar
	width: 7px
	background: #fff
	border: 1px solid #e8e8e8
	border-radius: 5px

::-webkit-scrollbar-thumb
	border-radius: 5px
	background-color: $accent-color

.tooltip
	position: absolute
	top: 0
	left: 0
	z-index: 9
	display: inline-block
	background-color: #fff
	padding: 2px 5px
	font-size: 13px
	box-shadow: 2px 2px 7px -5px #000
	color: #444

path
	fill: red

</style>
