"use strict";

import axios from 'axios';

export default {
	actions: {
		async createSubscriptionSession({ getters }, priceId) {
			const response = await axios.post(`${getters.strapiUrl}/api/subscriptions`, { price_id: priceId }, getters.requestHeader);
			return response.data;
		},

		async openPortal({ getters }) {
			const response = await axios.post(`${getters.strapiUrl}/api/subscriptions/portal`, {}, getters.requestHeader);
			return response.data;
		},
	}
}
