<template>
	<div class="header" v-click-outside="closeAllMenus">
		<img 
			class="logo" 
			src="https://releasecat.io/graf/releasecat_logo.svg" 
			alt="Releasecat"
			height="28"
		>

		<div class="head-section">
			<div class="name-controller">
				<span class="name">{{ activeStream.attributes.name }}</span>
				<button class="edit" @click="editStream"></button>
			</div>
			<div class="posts" v-if="$route.path === '/'">
				<button class="highlight" @click="showBannerListModal">Posts</button>
			</div>
		</div>

		<div class="tail-section">
			<div class="wide">
				<div 
					class="resources" 
					:class="{ boarding: highlightResources }"
					@mouseenter="highlightResources = false"
				>
					<button class="expand" @click="showResourcesMenu = !showResourcesMenu"></button>
					<transition mode="out-in" name="menu">
						<div class="menu-list account" v-if="showResourcesMenu">
							<button type="button" @click="showEmbed">How to embed</button>
							<a href="https://docs.releasecat.io" target="_blank">Help Center</a>
							<button type="button" @click="showWhatsNew">What's new</button>
						</div>
					</transition>
				</div>
				<div 
					class="superadmin-users"
					v-if="user && user.role && user.role.name === 'Superadmin'"
				>
					<button 
						class="expand"
						@click="showSuperadminUsersMenu = !showSuperadminUsersMenu"
					>Users</button>
					<transition mode="out-in" name="menu">
						<div class="submenu" v-if="showSuperadminUsersMenu">
							<input
								class="menu-input search"
								type="text"
								placeholder="Wyszukaj email"
								v-model="superadminUserSearch"
								ref="userSearchInput"
							>
							<ul class="users-response" v-if="superadminUsers.length">
								<li
									class="user"
									:class="{ active: superadminPreviewUser && superadminPreviewUser.id === user.id }"
									v-for="user in superadminUsers"
									:key="user.id"
								>	
									<button class="select-user" type="button" @click="setPreviewUser(user)">
										{{ user.email }}
									</button>
								</li>
							</ul>
							<p v-else class="no-results">No results.</p>
						</div>
					</transition>

				</div>
				<div class="account">
					<button 
						class="expand"
						@click="showAccountMenu = !showAccountMenu"
					>Account</button>
					<transition mode="out-in" name="menu">
						<div class="menu-list account" v-if="showAccountMenu">
							<button type="button" @click="showAccountModal">Settings</button>
							<button type="button" @click="logout">Logout</button>
						</div>
					</transition>
				</div>
			</div>

			<div class="mobile">
				<button class="list" @click="showMobileMenu = !showMobileMenu">Menu</button>
				<transition mode="out-in" name="menu">
					<div class="menu" v-if="showMobileMenu">
						<template v-if="$route.path === '/'">
							<button type="button" @click="showBannerListModal">Posts list</button>
							<button type="button" @click="showEmbed">How to embed</button>
						</template>
						<a href="https://docs.releasecat.io" target="_blank">Help Center</a>
						<button type="button" @click="showWhatsNew">What's new</button>
						<button type="button" @click="showAccountModal">Settings</button>
						<button type="button" @click="logout">Logout</button>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	data: () => ({
		showAccountMenu: false,
		showMobileMenu: false,
		showResourcesMenu: false,
		highlightResources: false,
		showSuperadminUsersMenu: false,
		superadminUsers: [],
		superadminUserSearch: ''
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		},
		streams() {
			return this.$store.getters.streams;
		},
		activeStream() {
			return this.$store.getters.activeStream;
		},
		superadminPreviewUser: {
			get() {
				return this.$store.getters.superadminPreviewUser;
			},
			set(user) {
				this.$store.commit('setSuperadminPreviewUser', user);
			}
		}
	},
	methods: {
		editStream() {
			this.closeAllMenus();
			this.$eventBus.$emit('showEditStreamModal');
		},

		showEmbed() {
			this.closeAllMenus();
			this.$router.push('/embed');
		},

		showWhatsNew() {
			Releasecat.showSidePanel(null, 'eHpjMrdBnnroXgyM7pPavT')
		},

		showAccountModal() {
			this.closeAllMenus();
			this.$eventBus.$emit('showAccountModal');
		},

		showBannerListModal() {
			this.closeAllMenus();
			this.$eventBus.$emit('showBannerListModal');
		},

		highlightResourcesMenu() {
			this.highlightResources = true;
		},

		setPreviewUser(user) {
			if (this.superadminPreviewUser?.id === user.id) return;
			if (user.id === this.user.id)
				this.superadminPreviewUser = null;
			 else
				this.superadminPreviewUser = user;
			this.showSuperadminUsersMenu = false;
		},

		closeAllMenus() {
			this.showAccountMenu = false;
			this.showMobileMenu = false;
			this.showResourcesMenu = false;
			this.showSuperadminUsersMenu = false;
		},

		logout() {
			this.$store.dispatch('logout');
		},
	},
	created() {
		this.$eventBus.$on('highlightResourcesMenu', this.highlightResourcesMenu);
	},
	beforeDestroy() {
		this.$eventBus.$off('highlightResourcesMenu', this.highlightResourcesMenu);
	},
	watch: {
		showAccountMenu(bool) {
			if (bool && this.showResourcesMenu) 
				this.showResourcesMenu = false;
		},
		showResourcesMenu(bool) {
			if (bool && this.showAccountMenu) 
				this.showAccountMenu = false;
		},
		showSuperadminUsersMenu: {
			async handler(bool) {
				if (bool) {
					try {
						this.superadminUsers = await this.$store.dispatch('fetchUsers');

						this.$nextTick(() => {
							this.$refs.userSearchInput.focus();
						});
					} catch (e) {
						console.log(e);
					}
				} else {
					this.superadminUserSearch = '';
				}
			}
		},
		superadminUserSearch: {
			async handler(searchInput) {
				try {
					if (!this.showSuperadminUsersMenu)
						return;

					if (searchInput.length === 0)
						this.superadminUsers = await this.$store.dispatch('fetchUsers');
					else if (searchInput.length < 3)
						return;
					else
						this.superadminUsers = await this.$store.dispatch('fetchUsers', searchInput);
				} catch (e) {
					console.error(e);
				}
			}
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

@mixin expand-icon
	padding-right: 10px
	position: relative
	&::after
		content: ''
		display: block
		width: 24px
		height: 24px
		background: url('../assets/images/dashboard/icons/down-arrow.png') no-repeat center
		background-size: contain
		position: absolute
		top: 50%
		right: -15px
		transform: translateY(-50%)

@mixin menu-list
	position: absolute
	display: block
	top: calc(100% + 15px)
	left: 0px
	border: 1px solid $border-color
	background: #fff
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
	padding: 5px 15px
	z-index: 999
	button,
	a
		display: block
		padding: 8px 0
		cursor: pointer
		width: 100%
		text-align: left
		font-size: 14px
		white-space: nowrap
		text-decoration: none
		color: #000
		&:not(:last-child)
			border-bottom: 1px solid #e6e6e6
		&:hover
			color: $accent-active-color

.header
	padding: 10px 30px
	padding-right: 10px
	background: #fff
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
	position: relative
	display: flex
	align-items: center
	.logo
		margin-right: 25px

	.head-section, 
	.tail-section
		display: flex
		align-items: center
		button
			font-size: 16px
			display: flex
			align-items: center
			cursor: pointer
			&.expand
				@include expand-icon
			// &.highlight
				

	.head-section
		border-left: 1px solid $border-color
		.posts
			display: none
			padding: 5px 25px
			border-left: 1px solid #d7d7d7
		.name-controller
			padding: 5px 25px
			display: flex
			align-items: center
			button
				&.edit
					background: url('../assets/images/dashboard/icons/edit.png') no-repeat center
					width: 16px
					height: 16px
					margin-left: 15px
					background-size: contain
				&.save
					margin-left: 15px
					width: 16px
					height: 16px
					background: url('../assets/images/dashboard/icons/save.png') no-repeat center
					background-size: contain
					filter: $accent-color-filter
				&.cancel
					margin-left: 15px
					width: 12px
					height: 12px
					background: url('../assets/images/dashboard/icons/delete.png') no-repeat center
					background-size: contain
				&:hover
					filter: $accent-active-color-filter
			input
				font-size: 16px
				border: none
				border-bottom: 1px solid $accent-color
				width: 140px

	.tail-section
		margin-left: auto
		.superadmin-users
			border-left: 1px solid $border-color
			padding: 5px 25px
			.submenu
				@include menu-list
				left: auto
				right: 10px
				top: calc(100% + 5px)
				padding-top: 10px
				padding-bottom: 10px
				width: 300px
				.menu-input.search
					font-size: 13px
					padding: 5px 5px
					border-radius: 3px
					width: 100%
					border: none
					border-radius: 0
					border-bottom: 1px solid #d7d7d7
					&:focus,
					&:hover
						border-color: $accent-color
				.users-response
					list-style: none
					margin-top: 5px
					max-height: 200px
					overflow-y: auto
					.user
						button.select-user
							position: relative
							padding: 5px 0
							overflow: hidden
							text-overflow: ellipsis
				.no-results
					font-size: 13px
					width: 100%
					text-align: center
					margin-top: 10px

		.account
			border-left: 1px solid $border-color
			padding: 5px 25px
		.resources
			display: flex
			align-items: center
			padding: 5px 25px
			padding-left: 10px
			margin-right: 15px
			position: relative
			&.boarding
				animation-name: resourcesBoarding
				animation-duration: 1.5s
				animation-iteration-count: infinite
				box-shadow: 0 0 0 0 rgba($accent-color, .5)
				position: relative

			& > button
				display: inline-block
				width: 16px
				height: 16px
				background: url('../assets/images/dashboard/icons/info.svg') no-repeat center
				background-size: contain
				&::after
					right: -25px
			.menu-list
				@include menu-list
		button.logout
			padding: 5px 25px
			border-left: 1px solid $border-color

	.wide
		display: flex
		align-items: center
		flex: 1

		.head-section.banners
			display: none

		.account
			position: relative
			.menu-list
				@include menu-list

	.mobile
		display: none

@media screen and (max-width: 1280px)
	.header
		.head-section
			.posts
				display: block

@media screen and (max-width: 700px)
	.header
		padding: 10px 15px
		padding-right: 30px
		.logo
			margin-right: 15px
			height: 28px
		.head-section
			.posts
				display: none
			input
				width: 100px
			.name
				max-width: 100px
				overflow: hidden
				text-overflow: ellipsis
				white-space: nowrap
		.tail-section
			.wide
				display: none
			.mobile
				flex: 1
				display: flex
				justify-content: flex-end
				button.list
					font-size: 14px
					padding: 5px 15px
					display: flex
					align-items: center
					margin-left: 15px
					transition: .1s linear
					cursor: pointer
					text-transform: uppercase
					@include expand-icon
				.menu
					@include menu-list
					width: 150px
					left: calc(100% - 170px)

@media screen and (max-width: 480px)
	.header
		padding: 10px 20px
		.logo
			height: 20px
		.head-section
			.name-controller
				padding: 5px 15px
				input
					width: 100px
		.tail-section
			.mobile
				.list
					margin-left: 0

.menu-enter, .menu-leave-to
	transform: translateY(-10px)
	opacity: 0

.menu-enter-active, .menu-leave-active
	transition: all .2s ease

@keyframes resourcesBoarding
	70%
		box-shadow: 0 0 0 10px rgba($accent-color, 0)
	100%
		box-shadow: 0 0 0 0 rgba($accent-color, 0)
</style>