import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
		meta: {
			auth: true,
			layout: 'Dashboard'
		}
  },
	{
		path: '/embed',
		name: 'Embed',
		component: () => import('../views/Embed.vue'),
		meta: {
			auth: true,
			layout: 'Dashboard'
		}
	},
	{
		path: '/subscriptions',
		name: 'Subscriptions',
		component: () => import('../views/Subscriptions.vue')
	},
	{
		path: '/auth',
		name: 'Auth',
		component: () => import('../views/Auth.vue')
	},
	{
		path: '/auth/email-verification',
		name: 'AuthEmailVerification',
		component: () => import('../views/AuthEmailVerification.vue')
	},
	// {
	// 	path: '/login/:type?',
	// 	name: 'Login',
	// 	component: () => import('../views/Login.vue'),
	// },
	{
		path: '*',
		redirect: '/'
	}
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

function processQuery(params) {
	if (params.processSubscription) {
		localStorage.setItem('processSubscription', params.processSubscription);
	}

	if (params.message && params.type) {
		localStorage.setItem('message', `${params.type}:${params.message}`);
	}

	if (params.openPortal) {
		localStorage.setItem('openPortal', true);
	}
}

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.user;
  const requireAuth = to.matched.some(record => record.meta.auth);

	if (Object.keys(to.query)) {
		processQuery(to.query);
	}

  if (requireAuth && !currentUser) {
		next('/auth');
  } else {
    next();
  }
});

export default router;
