export default {
  bind(el, binding) {
    // define tooltip DOM element
    let tooltip = document.createElement("div");
    tooltip.classList.add('tooltip');
    /* styles for tooltip defined in App.vue */
    // add relative position to el
    if (el.style.position !== "absolute") {
      el.style.position = "relative";
    }
    // add event listeners
    el.addEventListener('mouseenter', () => {
      // add tooltip to DOM
      tooltip.innerHTML = binding.value;
			document.body.appendChild(tooltip);
    });
    el.addEventListener('mousemove', (e) => {
      // set position near cursor
      tooltip.style.top = e.pageY + 25 + 'px';
      tooltip.style.left = e.pageX + 10 + 'px';
    });
    el.addEventListener('mouseleave', () => {
      // remove tooltip on mouseleave
      tooltip.remove();
    });
  },
  unbind(el) {
    // remove event listeners on unbind
    el.removeEventListener('mouseenter', () => {});
    el.removeEventListener('mousemove', () => {});
    el.removeEventListener('mouseleave', () => {});
  }
}
