import axios from 'axios';

export default {
	state: {
		token: null,
		user: null,
		// token: process.env.NODE_ENV === 'development' 
		// ? (localStorage.getItem('token') !== undefined ? localStorage.getItem('token') : '') : '',
		// user: process.env.NODE_ENV === 'development' 
		// 	? (localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : null) : null,
	},
	getters: {
		token: state => state.token,
		user: state => state.user,
		requestHeader: state => {
			return {
				headers: {
					Authorization: `Bearer ${state.token}`,
				}
			}
		}
	},
	mutations: {
		setToken(state, token) {
			state.token = token;

			if (process.env.NODE_ENV === 'development') {
				localStorage.setItem('token', token);
			}
		},
		setUser(state, user) {
			state.user = user;

			if (process.env.NODE_ENV === 'development') {
				localStorage.setItem('user', JSON.stringify(user));
			}
		}
	},
	actions: {
		async resetPassword(_, email) {
			const data = {
				client_id: 'B5aHIl3hfvEtv6C1A20VJQNszcaFwc7Z',
				email,
				connection: 'Username-Password-Authentication'
			}
			const response = await axios.post(
				'https://releasecat.eu.auth0.com/dbconnections/change_password',
				data,
				{
					headers: { 'Content-Type': 'application/json' }
				});

			return response.data;
		},

		async fetchMe({ getters }) {
			const response = await axios.get(`${getters.strapiUrl}/api/users/me`, getters.requestHeader);
			return response.data;
		},

		async fetchUsers({ getters }, searchInput = null) {
			let response;

			if (searchInput)
				response = await axios.get(`${getters.strapiUrl}/api/users?filters[email][$contains]=${searchInput}`, getters.requestHeader);
			else 
				response = await axios.get(`${getters.strapiUrl}/api/users`, getters.requestHeader);

			const users = response.data;

			return users;
		},

		async updateUser({ getters, commit }, user) {
			const response = await axios.put(`${getters.strapiUrl}/api/users/${user.id}`, user, getters.requestHeader);
			commit('setUser', response.data);
			return response.data;
		},

		async deleteUser({ getters }) {
			const strapiUserId = getters.user.id;
			const auth0UserId = getters.user.auth0id;

			await axios.delete(`${getters.strapiUrl}/api/users/${strapiUserId}/${auth0UserId}`, getters.requestHeader);
		},

		logout() {
			if (localStorage.getItem('token')) {
				localStorage.removeItem('token');
			}
			if (localStorage.getItem('user')) {
				localStorage.removeItem('user');
			}
			const logoutUrl = process.env.NODE_ENV === 'development'
				? 'http://localhost:8080' + process.env.BASE_URL
				: 'https://releasecat.io' + process.env.BASE_URL;

			window.location.href = "https://auth.releasecat.io/v2/logout?client_id=B5aHIl3hfvEtv6C1A20VJQNszcaFwc7Z&returnTo=" + logoutUrl;
		}
	}
}