import axios from 'axios';

export default {
	state: {
		fields: [],
		activeField: null
	},
	getters: {
		fields: state => state.fields,
		activeField: state => state.activeField,
		field: state => (id) => state.fields.find(field => field.id === id)
	},
	mutations: {
		setFields(state, fields) {
			state.fields = fields;
		},
		setField(state, { id, data = null, options = null }) {
			const field = state.fields.find(field => field.id === id);
			if (data) {
				Object.assign(field.attributes, data);
			}
			if (options) {
				Object.assign(field.options, options);
			}
		},
		setActiveField(state, field) {
			state.activeField = field;
		}
	},
	actions: {
		async getFields({ commit, getters }, bannerId) {
			const response = await axios.get(`${getters.strapiUrl}/api/fields/${bannerId}`, getters.requestHeader);
			const fields = response.data.data;
			commit('setFields', fields);
			return fields;
		},

		async createField({ getters, commit, dispatch }, { fieldData, options = {}, updateState = true }) {
			if (getters.superadminPreviewUser) throw new Error('You cannot create a field while previewing as a superadmin.');
			const multipartedFormData = await dispatch('getMultipartFormData', fieldData);

			const response = await axios.post(
				`${getters.strapiUrl}/api/fields`, 
				multipartedFormData, 
				{
					headers: {
						...getters.requestHeader.headers,
						'Content-Type': 'multipart/form-data'
					}
				}
			);
			const field = response.data.data;

			if (updateState) {
				commit('setFields', [...getters.fields, { ...field, options }]);
			}

			return field;
		},

		async updateField({ getters, commit, dispatch }, { data, id, updateState = true }) {
			if (getters.superadminPreviewUser) throw new Error('You cannot update a field while previewing as a superadmin.');
			const multipartedFormData = await dispatch('getMultipartFormData', data);

			const response = await axios.put(
				`${getters.strapiUrl}/api/fields/${id}`, 
				multipartedFormData, 
				{
					headers: {
						...getters.requestHeader.headers,
						'Content-Type': 'multipart/form-data'
					}
				}
			);

			const field = response.data.data;
			if (updateState) {
				commit('setField', { id, data: field.attributes });
			}

			return field;
		},

		async deleteField({ getters, commit, dispatch }, fieldId) {
			if (getters.superadminPreviewUser) throw new Error('You cannot delete a field while previewing as a superadmin.');
			const field = getters.field(fieldId);
			if (field.attributes.image?.data?.id) {
				dispatch('deleteImage', field.attributes.image.data.id);
			}
			const response = await axios.delete(`${getters.strapiUrl}/api/fields/${fieldId}`, getters.requestHeader);
			const deletedField = response.data.data;
			commit('setFields', getters.fields.filter(f => f.id !== deletedField.id));

			return field;
		}
	}
}