import axios from 'axios';

export default {
	state: {
		banners: [],
		activeBanner: null
	},
	getters: {
		banners: state => state.banners,
		activeBanner: state => state.activeBanner,
		banner: state => (id) => state.banners.find(banner => banner.id === id),
		sortedBanners: state => {
			return state.banners.slice().sort((a, b) => {
				if (a.attributes.releaseDate === b.attributes.releaseDate) {
					return b.id - a.id;
				}
				return new Date(b.attributes.releaseDate) - new Date(a.attributes.releaseDate);
			});
		}
	},
	mutations: {
		setBanners(state, banners) {
			state.banners = banners;
		},
		setActiveBanner(state, banner) {
			state.activeBanner = banner;
		}
	},
	actions: {
		async getBanner({ getters }, { streamId, bannerId }) {
			const response = await axios.get(`${getters.strapiUrl}/api/banners/${streamId}/${bannerId}`, getters.requestHeader);
			const banner = response.data.data;
			return banner;
		},

		async getBanners({ commit, getters }, streamId) {
			const response = await axios.get(`${getters.strapiUrl}/api/banners/${streamId}`, getters.requestHeader);
			const banners = response.data.data;
			commit('setBanners', banners);
			return banners;
		},

		async createBanner({ commit, getters }, bannerData) {
			if (getters.superadminPreviewUser) throw new Error('You cannot create a post while previewing as a superadmin.');
			const response = await axios.post(`${getters.strapiUrl}/api/banners`, { data: bannerData }, getters.requestHeader);
			const banner = response.data.data;
			commit('setBanners', [...getters.banners, banner]);
			return banner;
		},

		async updateBanner({ commit, getters }, { data, id }) {
			if (getters.superadminPreviewUser) throw new Error('You cannot update a post while previewing as a superadmin.');
			const response = await axios.put(`${getters.strapiUrl}/api/banners/${id}`, { data }, getters.requestHeader);
			const banner = response.data.data;
			commit('setBanners', getters.banners.map(b => b.id === banner.id ? banner : b));
			return banner;
		},

		async deleteBanner({ getters, commit, dispatch }, bannerId) {
			if (getters.superadminPreviewUser) throw new Error('You cannot delete a post while previewing as a superadmin.');
			const fields = await dispatch('getFields', bannerId);
			const promises = [];
			fields.forEach(field => {
				const promise = dispatch('deleteField', field.id);
				promises.push(promise);
			});

			await Promise.all(promises);

			const response = await axios.delete(`${getters.strapiUrl}/api/banners/${bannerId}`, getters.requestHeader);
			const banner = response.data.data;
			commit('setBanners', getters.banners.filter(b => b.id !== banner.id));
			if (banner.id === getters.activeBanner.id) {
				commit('setActiveBanner', null);
			}
			return banner;
		},

		async generateHtml(_, bannerNode) {
			const bannerClone = bannerNode.cloneNode(true);
			const feedback = bannerClone.querySelector('.feedback');
			bannerClone.style.height = `${bannerNode.offsetHeight}px`;

			if (feedback) {
				const originalFeedbackNode = bannerNode.querySelector('.feedback');
				const feedbackHeight = originalFeedbackNode.offsetHeight 
					+ parseInt(originalFeedbackNode.style['margin-top']);
				bannerClone.style.height = `${bannerNode.offsetHeight - feedbackHeight}px`;
				bannerClone.setAttribute('data-feedback-height', feedbackHeight);

				feedback.remove();
			}
			const html = bannerClone.outerHTML;
			return html;
		}
	}
}