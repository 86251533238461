<template>
	<div class="dashboard-layout">
		<template v-if="isDataFetched">
			<div class="superadmin-preview" v-if="superadminPreviewUser">
				<span class="title">Superadmin preview</span>
				<span class="useremail">{{ superadminPreviewUser.email }}</span>
				<button 
					type="button"
					class="close"
					@click="() => { superadminPreviewUser = null }"
				>Close</button>
			</div>
			<DashboardHeader v-if="streams.length > 0"/>
			<router-view v-if="isDataFetched"/>
		</template>

		<template v-else>
			<div class="loading"></div>
		</template>
	</div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader';

export default {
	components: {
		DashboardHeader
	},
	data: () => ({
		isDataFetched: false
	}),
	computed: {
		streams() {
			return this.$store.getters.streams;
		},
		user() {
			return this.$store.getters.user;
		},
		superadminPreviewUser: {
			get() {
				return this.$store.getters.superadminPreviewUser;
			},
			set(user) {
				this.$store.commit('setSuperadminPreviewUser', user);
			}
		}
	},
	methods: {
		async processSubscription(priceId) {
			if (this.user.role.name !== 'Authenticated') {
				await this.openPortal();
			} else if (priceId) {
				const { session_url } = await this.$store.dispatch('createSubscriptionSession', priceId);
				window.location.href = session_url;
			} else {
				window.location.href = 'https://app.releasecat.io';
			}
		},

		async openPortal() {
			const response = await this.$store.dispatch('openPortal');
			window.location.href = response.url;
		},

		async processMessage(type, text) {
			const messagesWhitelist = {
				error: {
					subscriptionCancelled: 'Payment was cancelled'
				},
				success: {
					subscriptionSuccess: 'The payment has been successfully processed'
				}
			}

			if (messagesWhitelist[type] && messagesWhitelist[type][text]) {
				this.$toast.open({ type, message: messagesWhitelist[type][text] });
			}
		},
	},
	async created() {
		if (localStorage.getItem('processSubscription')) {
			const priceId = localStorage.getItem('processSubscription');
			localStorage.removeItem('processSubscription');
			await this.processSubscription(priceId);
			return;
		}

		if (localStorage.getItem('message')) {
			const message = localStorage.getItem('message');
			if (message.split(':').length !== 2) {
				return;
			}
			const [type, text] = message.split(':');
			
			localStorage.removeItem('message');
			await this.processMessage(type, text);
		}

		if (localStorage.getItem('openPortal')) {
			localStorage.removeItem('openPortal');

			await this.processSubscription(null)
		}

		await this.$store.dispatch('getStreams');
		await this.$store.dispatch('getTemplates');
		await this.$store.dispatch('getComboIcons');
		await this.$store.dispatch('getFeedbackIcons');

		if (this.streams.length > 0) {
			this.$store.commit('setActiveStream', this.streams[0]);
		} else {
			const stream = await this.$store.dispatch('createStream', {
				name: 'App name'
			});
			this.$store.commit('setActiveStream', stream);
		}
		this.isDataFetched = true;
	},
	mounted() {
		const fonts = require('../data/fonts');

		for (const font in fonts) {
			const linkNode = document.createElement('link');
			linkNode.rel = 'stylesheet';
			linkNode.href = fonts[font];
			document.head.appendChild(linkNode);
		}
	},
	watch: {
		superadminPreviewUser: {
			async handler(user) {
				try {
					// this.$store.commit('setBanners', []); // in active stream watcher
					// this.$store.commit('setFields', []); // in active banner watcher
					this.$store.commit('setActiveBanner', null);
					if (user === null) {
						await this.$store.dispatch('getStreams');
						this.$store.commit('setActiveStream', this.streams[0]);
					} else {
						await this.$store.dispatch('getStreams', user.id);
						if (this.streams.length > 0) {
							this.$store.commit('setActiveStream', this.streams[0]);
						} else {
							this.$toast.open({
								message: 'No streams found',
								type: 'error'
							});
							this.superadminPreviewUser = null;
						}
					}
				} catch (e) {
					console.error(e);
					this.$toast.open({
						message: e.response?.data.error.message || e,
						type: 'error'
					});
				}
			}
		}
	}
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/_variables'

.dashboard-layout
	min-height: 100vh
	position: relative
	background-color: #fdfdfd
	.superadmin-preview
		background: $accent-color
		color: #fff
		padding: 2px 10px
		display: flex
		font-size: 14px
		.useremail
			margin-left: 10px
		button.close
			margin-left: 10px
			color: #fff
			text-transform: uppercase
			&:hover
				color: $accent-active-color
				cursor: pointer
	.loading
		display: block
		width: 128px
		height: 128px
		background: url('../assets/images/dashboard/icons/autosave.gif') no-repeat center
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
</style>