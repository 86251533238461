module.exports = [
	{
		name: 'feedbackIconGood',
		data: {
			imageName: 'good.svg',
			type: 'good'
		}
	},
	{
		name: 'feedbackIconBad',
		data: {
			imageName: 'bad.svg',
			type: 'bad'
		}
	}
];