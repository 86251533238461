module.exports = [
	{
		name: 'comboIconFunction',
		data: {
			name: "Function",
			color: "Yellow",
			imageName: "function.png"
		}
	},
	{
		name: 'comboIconUpdate',
		data: {
			name: "Update",
			color: "Green",
			imageName: "update.png"
		}
	},
	{
		name: 'comboIconBugfix',
		data: {
			name: "Bug fix",
			color: "Red",
			imageName: "bug_fix.png"
		}
	},
	{
		name: 'comboIconImprove',
		data: {
			name: "Improve",
			color: "Blue",
			imageName: "improve.png"
		}
	},
]